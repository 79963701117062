import React, { useRef, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Image from "../Image/Image";
import CameraIcon from "../../assets/images/video-icon.svg";
import { NotificationServiceDownModal, FailedMultiInvitesModal, NotificationTrackingModal } from "../MultiParticipantInvite/MultiInviteModals";
import ZoomAppClient from '../../helper/zoomAppClient';
import {
    checkForAllNotificationsSentStatus as checkNotificationsSentStatus,
    filterTrackingDetailsByStatus,
    getParsedTrackingDetailsForC2CInvite,
    prepareMultiInviteStatusCheckRequest,
    updateTrackingDetailsStatus
} from '../../helper/inviteHelper';
import { sendC2CInvitation, checkC2CInviteStatus } from '../../service/guest.service';
import errorActions from "../../store/actions/errorActions";
import { ACTION_TYPES, STORE_NAMES } from '../../constants/store.constants';
import { BEFORE_REDIRECT_DELAY, REDIRECT_ROUTE_PATH } from '../../constants/redirect.constants';
import { PARAM_KEY_C2C_REDIRECT, PARAM_KEY_REDIRECT_LINK } from '../../constants/app.constants';
import { DISPLAY_FAILED_NOTIFICATIONS_STATUSES } from '../../constants/guest.constants';

const ClinicianToClinicianInvite = ({ invitee, notification }) => {
    const dispatch = useDispatch();
    const {
        provider: inviter,
        appConfig: {
            clientSettings: {
                dnp: {
                    statusCheckLimit = null,
                    pollingRetryInterval = null
                } = {}
            } = {}
        } 
    } = useSelector((state) => state?.[STORE_NAMES.APP]);

    const trackInviteTimerRef = useRef(null);
    const meetingLaunchDelayTimerRef = useRef(null);

    const [failedNotifications, setFailedNotifications] = useState(null);

    // modal toggles
    const [displayNotificationTrackingModal, setDisplayNotificationTrackingModal] = useState(false);
    const [displayNotificationServiceDownModal, setDisplayNotificationServiceDownModal] = useState(false);
    const [displayFailedNotificationsModal, setDisplayFailedNotificationsModal] = useState(false);

    const displayInviteSuccessModal = () => {
        dispatch({ type: ACTION_TYPES.INVITE_BUTTON_CLICKED, payload: { inviteButtonClicked: true, status: true } });
    };

    const hideAllModals = () => {
        setDisplayNotificationTrackingModal(false);
        setDisplayNotificationServiceDownModal(false);
        setDisplayFailedNotificationsModal(false);
    };

    const displayC2CInviteModal = ({ type, trackingDetails = null }) => {
        switch (type) {
            case "invitesSent":
                // display successfull invitation modal
                displayInviteSuccessModal();
                break;
            case "maxTimeLimitReached": // same as failedNotificationsError case so fallthrough
            case "pollingNotAllowed": // same as failedNotificationsError case so fallthrough
            case "failedNotificationsError":
                    const failedTrackingDetails = filterTrackingDetailsByStatus(trackingDetails, DISPLAY_FAILED_NOTIFICATIONS_STATUSES);
                    if(failedTrackingDetails.length === 0) {
                        displayInviteSuccessModal();
                        return;
                    }
                    setFailedNotifications(failedTrackingDetails);
                    setDisplayFailedNotificationsModal(true);
                break;
            case "serviceDownError":
                    setDisplayNotificationServiceDownModal(true);
                break;
            default:
                break;
        }
    };

    const resetMeetingLaunchDelayTimerRef = () => {
        if(meetingLaunchDelayTimerRef.current) {
            clearTimeout(meetingLaunchDelayTimerRef.current);
            meetingLaunchDelayTimerRef.current = null;
        }
    };

    const startMeeting = ({ zoomMeetingStartUrl }) => {
        const baseUrl = location.origin;
        const redirectRoutePath = `${baseUrl}/${REDIRECT_ROUTE_PATH}?${PARAM_KEY_REDIRECT_LINK}=${zoomMeetingStartUrl}&${PARAM_KEY_C2C_REDIRECT}=true`;
        meetingLaunchDelayTimerRef.current = setTimeout(() => ZoomAppClient.openExternalUrl(redirectRoutePath), BEFORE_REDIRECT_DELAY);
    };

    const initiateAfterPollingStopActions = ({ zoomMeetingDetails, inviteStatus, ...modalDisplayDependencies }) => {
        setFailedNotifications(null);
        hideAllModals();
        resetMeetingLaunchDelayTimerRef();
        displayC2CInviteModal(modalDisplayDependencies);
        if (inviteStatus) {
            startMeeting(zoomMeetingDetails);
        }
    }

    const startClinicianInviteStatusCheckPolling = async ({ iterationCount, trackingDetails, startTimeStamp, maxTimeLimitForPolling, serviceTimeout, serviceDownError, zoomMeetingDetails }) => {
        // update and keep track of polling limits
        iterationCount++;
        const isLastStatusCheck = iterationCount===+statusCheckLimit;

        /************* Polling stop scenarios before status tracking initiated *******************/
        const inviteSentStatus = checkNotificationsSentStatus(trackingDetails);
        if(inviteSentStatus===true) {
            initiateAfterPollingStopActions({ type: "invitesSent", inviteStatus:inviteSentStatus, zoomMeetingDetails });
            return;
        }

        const currentTimeStamp = dayjs();
        if(currentTimeStamp.diff(startTimeStamp, "ms") >= maxTimeLimitForPolling) {
            const type = serviceDownError ? "serviceDownError" : "maxTimeLimitReached";
            const afterPollingActionParams = { type, trackingDetails, inviteStatus:inviteSentStatus, zoomMeetingDetails };
            initiateAfterPollingStopActions(afterPollingActionParams);
            return;
        }

        const multiInviteStatusCheckRequest = prepareMultiInviteStatusCheckRequest(trackingDetails, isLastStatusCheck);
        if(multiInviteStatusCheckRequest.trackingIds.length === 0) {
            initiateAfterPollingStopActions({ type: "pollingNotAllowed", inviteStatus:inviteSentStatus, trackingDetails, zoomMeetingDetails });
            return;
        }

        /*******************************************************************************************/

        // track errors for notification service down scenario
        let serviceError = null;

        // initiate the status check
        try {
            const c2cInviteStatusCheckResponse = await checkC2CInviteStatus(multiInviteStatusCheckRequest, serviceTimeout);
            // update tracking details for next status check call
            if(c2cInviteStatusCheckResponse && Array.isArray(c2cInviteStatusCheckResponse) && c2cInviteStatusCheckResponse.length > 0) {
                updateTrackingDetailsStatus(trackingDetails, c2cInviteStatusCheckResponse);
            }
        }
        catch(exception) {
            const serverErrors = exception?.response?.data?.errors;
            if (serverErrors && Array.isArray(serverErrors) && serverErrors.length > 0) {
                serviceError = serverErrors.shift();
            }
        }

        /************* Polling stop scenarios after status tracking completed *******************/
        if(isLastStatusCheck) {
            const type = serviceError ? "serviceDownError" : "failedNotificationsError";
            const afterPollingActionParams = { type, trackingDetails, inviteStatus:inviteSentStatus, zoomMeetingDetails };
            initiateAfterPollingStopActions(afterPollingActionParams);
            return;
        }
        /*******************************************************************************************/

        // start the next poll
        const nextPollDependencies = { iterationCount, trackingDetails, startTimeStamp, maxTimeLimitForPolling, serviceTimeout, serviceDownError: serviceError, zoomMeetingDetails };
        trackInviteTimerRef.current = setTimeout(startClinicianInviteStatusCheckPolling, pollingRetryInterval, nextPollDependencies);
    };

    const prepareDepndencieAndStartPolling = (parsedInviteResponse) => {
        if(pollingRetryInterval===null || statusCheckLimit ===null) {
            dispatch(errorActions.setErrorHandler({ code: 14000 }));
            return;
        }
        const { validTrackingDetails: trackingDetails, ...zoomMeetingDetails } = parsedInviteResponse;
        // set initial polling dependencies
        const iterationCount = 0;
        const startTimeStamp = dayjs();
        const maxTimeLimitForPolling = pollingRetryInterval*statusCheckLimit; // milliseconds
        const serviceTimeout = +pollingRetryInterval;
        const serviceDownError = null;
        setDisplayNotificationTrackingModal(true);
        startClinicianInviteStatusCheckPolling({ iterationCount, trackingDetails, startTimeStamp, maxTimeLimitForPolling, serviceTimeout, serviceDownError, zoomMeetingDetails }); 
    };

    const sendC2CInvite = async () => {
        try {
            const inviteClinicianRequstBody = {
                inviter,
                invitee,
                notification
            }
            const  inviteGuestResponse = await sendC2CInvitation(inviteClinicianRequstBody);

            const parsedInviteResponse = getParsedTrackingDetailsForC2CInvite(inviteGuestResponse, notification);
            if(parsedInviteResponse===null) {
                // display failed modal if invalid response
                dispatch(errorActions.setErrorHandler({ code: 14000 }));
                return;
            }
            prepareDepndencieAndStartPolling(parsedInviteResponse);
        }
        catch(exception) {
            dispatch(errorActions.setErrorHandler({ code: 14000 }));
        }
    };

    const handleCameraIconClick = () => {
        if(!displayNotificationTrackingModal) {
            sendC2CInvite();
        }
    };

    const handleFailedInvitesModalClose = () => {
        // clear failed notifications and modal close logic
        setFailedNotifications(null);
        setDisplayFailedNotificationsModal(false);
    };

    const handleNotificationServiceDownModalClose = () => {
        // clear failed notifications and modal close logic
        setFailedNotifications(null);
        setDisplayNotificationServiceDownModal(false);
    };

    const cleanUpInviteDependencies = () => {
        // cleanup polling dependencies
        if(trackInviteTimerRef.current) {
            clearTimeout(trackInviteTimerRef.current);
            trackInviteTimerRef.current = null;
        }
        resetMeetingLaunchDelayTimerRef();
        setFailedNotifications(null);
        hideAllModals();
    };

    useEffect(() => {
        return () => cleanUpInviteDependencies();
    }, []);

    return  <>
            <Image
                className="vve-video-icon"
                id="call-video-icon"
                src={CameraIcon}
                alt="video-icon.svg"
                onClick={handleCameraIconClick}
            />
            { displayFailedNotificationsModal && 
                <FailedMultiInvitesModal failedNotifications={failedNotifications} onCloseHandler={handleFailedInvitesModalClose} /> 
            }
            { displayNotificationServiceDownModal &&
                <NotificationServiceDownModal onCloseHandler={handleNotificationServiceDownModalClose} />
            }
            { displayNotificationTrackingModal &&
                <NotificationTrackingModal onCloseHandler={setDisplayNotificationTrackingModal} />
            }
    </>

};

/* PropTypes */
ClinicianToClinicianInvite.propTypes = {
    invitee: PropTypes.any,
    notification: PropTypes.any
};

export default ClinicianToClinicianInvite;