const SERVICE_ROUTES = {
    FETCH_SETTINGS: 'setup',
    FETCH_DIRECTORY_CONFIG: 'config',
    FETCH_PING_TOKEN_DETAILS: 'refreshToken',
    FETCH_LANGUAGE_ROUTE: 'interpreter/languages?zoomMeetingId={zoomMeetingID}&region={region}&visitType={visitType}',
    FETCH_SERVICE_AREA_ROUTE: '{region}/serviceArea',
    FETCH_GUEST_SERVICE_ROUTE: 'invite/guest',
    FETCH_SNOW_SERVICE_ROUTE: 'snow',
    FETCH_INTERPRETER_SERVICE_ROUTE: 'invite/interpreter',
    INVITE_AUDIO_INTERPRETER_SERVICE_ROUTE: 'invite/dialout',
    FETCH_DEPARTMENT_LIST_ROUTE: 'oncall/regions/{region}/departments',
    FETCH_MEDICAL_CENTERS_ROUTE: 'oncall/regions/{region}/medicalcenters',
    FETCH_DEPARTMENT_BY_MEDICAL_CENTER_ROUTE: 'oncall/regions/{region}/medicalcenters/{medcenterid}/departments',
    FETCH_PROVIDER_LIST_BY_DEPTID: 'oncall/regions/{region}/medicalcenters/{medcenterid}/departments/{departmentid}/clinicians',
    FETCH_NEXT_ONCALL_DEPTID: 'oncall/regions/{region}/medicalcenters/{medcenterid}/departments/{departmentid}/clinicians/nextschedule',
    FETCH_CLINICIAN_LIST: 'regions/{region}/clinicians',
    FETCH_NEXT_ONCALL_CLINICIAN: 'oncall/regions/{region}/clinicians/nextschedule',
    FETCH_CLINICIAN_MEDICAL_CENTERS_ROUTE: 'regions/{region}/medicalcenters',
    FETCH_CLINICIAN_DEPARTMENT_BY_MEDICAL_CENTER_ROUTE: 'regions/{region}/medicalcenters/{medicalCenter}/departments',
    FETCH_CLINICIAN_FILTERED_BY_LIST_MEDICAL_CENTERS: 'regions/{region}/medicalcenters/{medicalCenter}/clinicians',
    FETCH_CLINICIAN_FILTERED_LIST: 'regions/{region}/medicalcenters/{medicalCenter}/departments/{departmentid}/clinicians',
    READ_ESB_AUTH_COOKIE: 'token',
    DOWNLOAD_ATTENDEE_REPORT: 'reports/participantlist/download/{zoomMeetingUuid}',
    FETCH_GROUP_LIST: 'reports/groupclasslist?nuid={nuid}&startdate={startdate}&enddate={enddate}&region={region}',
    FETCH_GROUP_PARTICIPANT_LIST: 'reports/participantlist?zoomMeetingUuid={zoomMeetingUUID}',
    TRACK_MULTI_INVITE: 'invites/status',
    SEND_C2C_INVITE: 'invite/clinician',
    TRACK_C2C_INVITE: 'invite/clinician/status',
    FETCH_FAVORITE_LIST:'regions/{region}/clinicians/favorites',
    ADD_FAVORITE:'regions/{region}/favoriteIds/{favoriteIDs}/clinicians/favorites',
    REMOVE_FAVORITE:'regions/{region}/favoriteIds/{favoriteIDs}/clinicians/favorites/delete',
};

const SERVICE_SUCCESS_STATUS = "success";
const SYSTEM_ERROR_CODES = [6004, 6005, 6006, 9007, 9009, 9008, 11000, 11001];
const APP_UNAVAILABLE_CODE = "9008"; 

const DEFAULT_HTTP_OPTIONS = 	{
    headers: {
        "Content-Type": "application/json"
    },
    timeout: 30000,
    withCredentials: true
};

const HEADER_KEY_NAME = {
    envlbl: "esb-envlbl",
    requestUser: "X-REQUEST-USER"
};

const ERROR_PAGE_CODE_MAP = {
    APP_UNAVAILABLE_CODE: "9008",
    INTEGRATED_UNAVAILABLE_CODE: "13000",
    NON_INTEGRATED_UNAVAILABLE_CODE: "13001",
    DIRECTORY_UNAVAILABLE_CODE: "13002"
};

const PARAM_KEY_AUTH_TOKEN = "token";

export {
    SERVICE_ROUTES,
    SERVICE_SUCCESS_STATUS,
    SYSTEM_ERROR_CODES,
    APP_UNAVAILABLE_CODE,
    DEFAULT_HTTP_OPTIONS,
    HEADER_KEY_NAME,
    ERROR_PAGE_CODE_MAP,
    PARAM_KEY_AUTH_TOKEN
};
