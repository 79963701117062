import { fetchPingTokenDetails, readEsbAuthCookie } from "../../service/auth.service";
import { ACTION_TYPES } from '../../constants/store.constants';

const refreshTokenFailure = () => ({
    type: ACTION_TYPES.REFRESH_TOKEN_FAILURE
})

const refreshTokenFailureWithRetry = () => ({
    type: ACTION_TYPES.REFRESH_TOKEN_FAILURE_WITH_RETRY
})

const refreshTokenSuccess = () => ({
    type: ACTION_TYPES.REFRESH_TOKEN
})

export const keepAliveTheAppWithRetry = (allowedRetryCount, attemptedRetries) => async (dispatch) => {
    if (attemptedRetries < allowedRetryCount) {
        let refreshCallStatus = false
        try {
            await fetchPingTokenDetails();
            dispatch(readAuthTokenCookie());
            refreshCallStatus = true
            dispatch(refreshTokenSuccess());
        } catch (err) {
            refreshCallStatus = false
            // if(attemptedRetries === (allowedRetryCount-1)){ // max count 3 and count starts from 0 so 3 - 1 = 2

            // }
            if (attemptedRetries === (allowedRetryCount - 1)) {
                dispatch({ type: ACTION_TYPES.APP_INITIALIZATION_FAILURE })
            }
        }
        finally {
            if (!refreshCallStatus) {
                dispatch(refreshTokenFailureWithRetry())
            }
        }
    }
}

export const keepAliveTheApp = () => async (dispatch) => {
    try {
        await fetchPingTokenDetails();
        dispatch(readAuthTokenCookie());
        dispatch(refreshTokenSuccess());
    } catch (err) {
        dispatch(refreshTokenFailure())
    }
}

export const readAuthTokenCookie = () => async (dispatch) => {
    try {
        const authCookieResponse = await readEsbAuthCookie();
        dispatch(setAuthToken(authCookieResponse?.token));
    }
    catch(error) {
        console.log("readAuthTokenCookieException", error);
    }
}

const setAuthToken = (payload) => ({
    type: ACTION_TYPES.SET_AUTH_TOKEN,
    payload
});

