import React from "react";
import { useSelector } from "react-redux";
import Button from "../Button/Button";
import { STORE_NAMES } from "../../constants/store.constants";
import ZoomAppClient from "../../helper/zoomAppClient";
import { PARAM_KEY_AUTH_TOKEN } from "../../constants/service.constants";
import { PARAM_KEY_MEETING_UUID, PARAM_KEY_ATTENDEE_REPORT_FILE } from "../../constants/app.constants";
import dayjs from "dayjs";

const DownloadReportButton = (downloadReportProps) => {
    const { btnClass, btnLabel = null, zoomMeetingId, zoomMeetingUuid, title, dateTime } = downloadReportProps;
    const { authToken } = useSelector(state => state?.[STORE_NAMES.APP]);
    const { attendeeReportDetails: { hideDownloadFeature } } = useSelector(state => state?.[STORE_NAMES.DIRECTORY_CONFIG]);

    const getReportFileName = () => {
        const formattedTitle = title.replace(/\s+/g,"_");
        const formattedDateTime = dayjs(dateTime).format("MM-DD-YYYY");
        return `${formattedDateTime}_${formattedTitle}_${zoomMeetingId}.xlsx`;
    };

    const prepareExternalReportPageUrl = () => {
        const paramList = new URLSearchParams({
            [PARAM_KEY_AUTH_TOKEN]: encodeURIComponent(authToken),
            [PARAM_KEY_MEETING_UUID]: encodeURIComponent(zoomMeetingUuid),
            [PARAM_KEY_ATTENDEE_REPORT_FILE]: encodeURIComponent(getReportFileName())
        });
        const baseUrl = location.origin;
        const generateReportPagePath = "download-attendee-report";
        return `${baseUrl}/${generateReportPagePath}?${paramList.toString()}`;
    };

    const handleDownloadClick = () => {
        ZoomAppClient.openExternalUrl(prepareExternalReportPageUrl());
    };

    return <>
        { hideDownloadFeature === false &&
            <Button class={btnClass} onClick={handleDownloadClick}>{ btnLabel }</Button>
        }
    </>
};
  
export default DownloadReportButton;