import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nav from 'react-bootstrap/Nav';
import OncallDirectory from "../OncallDirectory/OncallDirectory";
import ClinicianDirectory from "../ClinicianDirectory/ClinicianDirectory";
import Reports from '../Reports/Reports';
import RefreshToken from '../RefreshToken/RefreshToken';
import { prepareMenuItems } from "../../helper/menuHelper";
import { UpdateSelectedDepartment } from '../../store/actions/DepartmentListAction';
import { UpdateSelectedProvider } from "../../store/actions/ProviderListAction";
import { UpdateSelectedClinician } from "../../store/actions/ClinicianListAction";
import TabAction from '../../store/actions/TabAction';
import { STORE_NAMES } from "../../constants/store.constants";
import { DIRECTORY_FEATURE, ONCALL_FEATURE, REPORT_FEATURE, OLD_TAB_KEY_MAP_FOR_NEW_MENU } from "../../constants/tab.constants";

const NewDirectoryMenu = ({ menuItems, directoryRegion }) => {
    const dispatch = useDispatch();
    const [menuList, setMenuList] = useState([]);
    const [selectedMenuKey, setSelectedMenuKey] = useState(null);
    const { deviceType } = useSelector(state => state?.[STORE_NAMES.ZOOM_CLIENT]);

    const handleMenuClick = (selectedMenuKey) => {
        setSelectedMenuKey(selectedMenuKey);
       
        // this logic is to update store value to notify the underlying component about the selected menu
        if(OLD_TAB_KEY_MAP_FOR_NEW_MENU?.[selectedMenuKey]) {
            dispatch(TabAction.setCurrentTabContext(OLD_TAB_KEY_MAP_FOR_NEW_MENU?.[selectedMenuKey]));
        }

         // the following changes are done as is like directory tab to reset other dependent store values 
        dispatch(UpdateSelectedDepartment(''));
        dispatch(UpdateSelectedProvider(null));
        dispatch(UpdateSelectedClinician(null));
    };

    useEffect(() => {
        const parsedMenuList = prepareMenuItems({ menuItems, deviceType, directoryRegion });
        setMenuList(parsedMenuList);
        const selectedMenuItem = parsedMenuList.find(menuItem => menuItem.selected===true);
        setSelectedMenuKey(selectedMenuItem?.id);
    }, []);

    return  selectedMenuKey && 
            <>
                <div className='vve-directory'>
                    <Nav variant="pills" defaultActiveKey={selectedMenuKey} onSelect={handleMenuClick} className="vve-new-menu">
                        {menuList.map((menuItem, index) => (
                            <Nav.Item key={`${index}-${menuItem.id}`}>
                                <Nav.Link eventKey={menuItem.id}>{menuItem.title}</Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                    {selectedMenuKey===ONCALL_FEATURE &&
                        <OncallDirectory></OncallDirectory>
                    }
                    {selectedMenuKey===DIRECTORY_FEATURE &&
                        <ClinicianDirectory></ClinicianDirectory>
                    }
                    {selectedMenuKey===REPORT_FEATURE &&
                        <Reports></Reports>
                    }
                </div>
                <RefreshToken />
            </>
};

export default NewDirectoryMenu;