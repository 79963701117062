import { fetchAppSettings, fetchDirectoryConfig } from '../../service/setting.service';
import zoomAppClient from '../../helper/zoomAppClient';
import { ACTION_TYPES } from '../../constants/store.constants';
import { getTabSettings, prepareProviderDetails, validateAppInitializationDependencies } from '../../helper/commonUtils';
import apiClient from "../../helper/apiClient";
import { ERROR_PAGE_CODE_MAP } from '../../constants/service.constants';
import { readAuthTokenCookie } from './SessionAction';

const displayErrorPage = (error) => (dispatch) => {
    const ERROR_PAGE_CODE_ACTION_MAP = {
        default: ACTION_TYPES.APP_INITIALIZATION_FAILURE,
        [ERROR_PAGE_CODE_MAP.APP_UNAVAILABLE_CODE]: ACTION_TYPES.APP_UNAVAILABLE,
        [ERROR_PAGE_CODE_MAP.INTEGRATED_UNAVAILABLE_CODE]: ACTION_TYPES.INTEGRATED_UNAVAILABLE,
        [ERROR_PAGE_CODE_MAP.NON_INTEGRATED_UNAVAILABLE_CODE]: ACTION_TYPES.NON_INTEGRATED_UNAVAILABLE,
        [ERROR_PAGE_CODE_MAP.DIRECTORY_UNAVAILABLE_CODE]: ACTION_TYPES.DIRECTORY_UNAVAILABLE
    };
    let actionType = ERROR_PAGE_CODE_ACTION_MAP.default;
    const serverErrors = error?.response?.data?.errors;
    if (serverErrors && Array.isArray(serverErrors)) {
        const { code = "default" } = serverErrors.shift() ?? {};
        actionType = ERROR_PAGE_CODE_ACTION_MAP?.[code] ?? ERROR_PAGE_CODE_ACTION_MAP.default;
    }
    dispatch({ type: actionType });
}

export const setAppSettings = (appSettings, uid = null) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.FETCH_APP_SETTINGS_SUCCESS, payload: appSettings });
    const tabSettings = getTabSettings(appSettings?.featureMatrix || []);
    dispatch({ type: ACTION_TYPES.SET_TAB_SETTINGS, payload: tabSettings });
    const supportSettings = appSettings?.supportNumbers;
    const categories = appSettings?.category;
    dispatch({ type: ACTION_TYPES.FETCH_SUPPORT_CATEGORY, payload: categories });
    dispatch({ type: ACTION_TYPES.FETCH_SUPPORT_INFO, payload: supportSettings });
    const providerDetails = prepareProviderDetails(appSettings, uid);
    dispatch({ type: ACTION_TYPES.SET_PROVIDER_DETAILS, payload: providerDetails });
}

export const intialiseAppSetup = (uid, isDirectory = false) => async (dispatch) => {
    try {
        dispatch({ type: ACTION_TYPES.SET_UID, payload: uid });
        dispatch(readAuthTokenCookie());

        // configure zoom sdk only for meeting app context
        await zoomAppClient.configureApp(isDirectory);
        // will always return mock for directory app context and when running inside a browser
        const zoomClientDetails = await zoomAppClient.getClientDetails(isDirectory);
        dispatch({ type: ACTION_TYPES.SET_ZOOM_CLIENT_DETAILS, payload: zoomClientDetails });

        const appSettings = await fetchAppSettings(zoomClientDetails?.meetingID, uid, isDirectory);
        // will not make actual backend call when inside meeting app (will only call when in directory app)
        const directoryConfig = await fetchDirectoryConfig(isDirectory);

        // validate both appSettings and directoryConfig when in directoryapp context and only appSettings for meeting app context
        const isValid = validateAppInitializationDependencies({ appSettings, directoryConfig }, isDirectory);
        if (!isValid) {
            dispatch({ type: ACTION_TYPES.APP_INITIALIZATION_FAILURE });
            return;
        }

        // following are required for both meeting and directory app context
        if (appSettings.regionFeatureFlag) {
            dispatch({
                type: ACTION_TYPES.FETCH_APP_REGION_SETTINGS_SUCCESS, payload: {
                    ...appSettings,
                    displayRegionList: appSettings.enableRegionList?.map(val => Object.keys(val)[0])
                }
            });
        } else {
            dispatch(setAppSettings(appSettings, uid));
        }

        // only required when in directory app context
        if (isDirectory === true) {
            // configure clinician directory bff headers with nuid
            const { appointment: { provider: { nuid }, appointmentRegion } } = appSettings;
            apiClient.configureClinicianDirectoryBffInstance(nuid);
            dispatch({ type: ACTION_TYPES.FETCH_DIRECTORY_CONFIG_SUCCESS, payload: { directoryConfig, appointmentRegion } });
        }
    }
    catch (error) {
        console.log(`intialiseAppSetup error for ${isDirectory === true ? 'directory app' : 'meeting app'}`);
        console.log(error);
        dispatch(displayErrorPage(error));
    }
}
