import apiClient from '../helper/apiClient';
import { SERVICE_ROUTES } from '../constants/service.constants';

export const fetchPingTokenDetails = async() => {
    const httpClient = apiClient.getZoomAppBffInstance();
    return httpClient.post(SERVICE_ROUTES.FETCH_PING_TOKEN_DETAILS);
}

export const readEsbAuthCookie = async () => {
    const httpClient = apiClient.getZoomAppBffInstance();
    return httpClient.get(SERVICE_ROUTES.READ_ESB_AUTH_COOKIE);
}


