import { ACTION_TYPES } from "../../constants/store.constants";

const initialState = {
	appConfig: {
		clientSettings: null,
	},
	initializationErrorStatus: false,
	appUnavailableStatus: false,
	regionFeatureEnabled: null,
	enableRegionList: [],
	displayRegionList: [],
	uid: null,
	sharepointLink: null,
	integratedFlowUnavailableStatus: false,
	nonIntegratedFlowUnavailableStatus: false,
	directoryFlowUnavailableStatus: false,
	authToken: null,
	provider: null
}

const AppReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.SET_UID: 
			return {
				...state,
				uid: payload
			};
		case ACTION_TYPES.FETCH_APP_SETTINGS_SUCCESS:
			return {
				...state,
				appConfig: {
					clientSettings: payload?.clientConfigs
				},
				sharepointLink: payload?.sharepointLink
			};
		case ACTION_TYPES.FETCH_APP_REGION_SETTINGS_SUCCESS:
			return {
				...state,
				regionFeatureEnabled: payload.regionFeatureFlag,
				enableRegionList: payload.enableRegionList,
				displayRegionList: payload.displayRegionList
			}
		case ACTION_TYPES.APP_INITIALIZATION_FAILURE:
			return {
				...state,
				initializationErrorStatus: true
			};
		case ACTION_TYPES.APP_UNAVAILABLE:
			return {
				...state,
				appUnavailableStatus: true
			};
		case ACTION_TYPES.INTEGRATED_UNAVAILABLE:
			return {
				...state,
				integratedFlowUnavailableStatus: true
			};
		case ACTION_TYPES.NON_INTEGRATED_UNAVAILABLE:
			return {
				...state,
				nonIntegratedFlowUnavailableStatus: true
			};
		case ACTION_TYPES.DIRECTORY_UNAVAILABLE:
			return {
				...state,
				directoryFlowUnavailableStatus: true
			};
		case ACTION_TYPES.SET_AUTH_TOKEN: 
			return {
				...state,
				authToken: payload
			};
		case ACTION_TYPES.SET_PROVIDER_DETAILS: 
			return {
				...state,
				provider: payload
			};
		default:
			return state;
	}
};

export default AppReducer;